export const menuItems = [
  //COMPONENT value == NAME value
  //PATH value == LINK value
  {
    id: 1,
    label: "menuitems.menu.text",
    isTitle: true,
  },
  {
    id: 2,
    label: "menuitems.dashboard.text",
    icon: "ri-dashboard-line",
    link: "/Xingzheng/dashboard",
  },
  {
    id: 3,
    label: "Buyers",
    icon: "ri-shield-user-line",
    link: "/Xingzheng/reg_buyers",
  },
  {
    id: 4,
    label: "Agents",
    icon: "ri-creative-commons-by-line",
    link: "/Xingzheng/reg_agents",
  },
  {
    id: 5,
    label: "Financiers",
    icon: "ri-stock-line",
    link: "/Xingzheng/reg_financiers",
  },
  {
    id: 6,
    label: "Promoters",
    icon: "ri-stackshare-line",
    link: "/Xingzheng/reg_promoters",
  },
  // {
  //   id: 7,
  //   label: "Activities",
  //   icon: "ri-lightbulb-flash-line",
  //   subItems: [
  //     {
  //       id: 8,
  //       label: "Logged in users",
  //       link: "",
  //     },
  //     {
  //       id: 9,
  //       label: "Registered users",
  //       link: "/auth/register-1",
  //     },
  //     {
  //       id: 10,
  //       label: "Recover password",
  //       link: "/auth/recoverpwd-1",
  //     },
  //     {
  //       id: 11,
  //       label: "Add project",
  //       link: "/Xingzheng/addProject",
  //     },
  //     {
  //       id: 12,
  //       label: "Pending loan approvals",
  //       link: "/Xingzheng/add_project",
  //     },
  //   ],
  // },

  // {
  //   id: 13,
  //   label: "Reports",
  //   icon: "ri-file-chart-line",
  //   subItems: [
  //     {
  //       id: 14,
  //       label: "All users",
  //       link: "",
  //     },
  //     {
  //       id: 15,
  //       label: "Buyers",
  //       link: "",
  //     },
  //     {
  //       id: 16,
  //       label: "Agents",
  //       link: "",
  //     },
  //     {
  //       id: 17,
  //       label: "Financiers",
  //       link: "",
  //     },
  //     {
  //       id: 18,
  //       label: "Promoters",
  //       link: "",
  //     },
  //     {
  //       id: 19,
  //       label: "Sales report",
  //       link: "",
  //     },
  //   ],
  // },
  {
    id: 20,
    label: "View Projects",
    icon: "ri-question-line",
    link: "/Xingzheng/support",
  },
  // {
  //   id: 9,
  //   label: "My Dream House",
  //   icon: "ri-building-4-line",
  //   link: "/dream-house",
  // },
  // {
  //   id: 10,
  //   label: "My House Plan",
  //   icon: "ri-building-line",
  //   link: "/house-preference",
  // },
];
